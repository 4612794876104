import { StatusCodes } from 'http-status-codes';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Clinic } from '../../model/clinic';
import { RootState } from '../store';
import { IAcceptClinicInvitationParams, IDenyClinicInvitationParams } from '../../types';
import { ThunkError } from '../root.types';
import SystemHelper from '../../helpers/SystemHelper';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';

export const fetchClinics = createAsyncThunk<Clinic[], any, { rejectValue: ThunkError }>(
    'patient​/fetchClinics',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/clinics`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

        if (response.status !== StatusCodes.OK && response.status !== StatusCodes.NOT_FOUND) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedClinics')} (${
                    response.msg
                })`,
            });
        }

        return response.data?.data?.items ?? [];
    }
);

export const acceptClinicInvitation = createAsyncThunk<
    void,
    IAcceptClinicInvitationParams,
    { rejectValue: ThunkError }
>('patient/acceptClinicInvitation', async (params, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const url = `${appState.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/accept`;
    const response = await SystemHelper.Fetch(
        appState,
        authenticationState,
        url,
        {
            method: 'POST',
        },
        true
    );

    if (response.status !== StatusCodes.NO_CONTENT) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedAcceptClinicInvitation')} (${
                response.msg
            })`,
        });
    }

    return null;
});

export const denyClinicInvitation = createAsyncThunk<void, IDenyClinicInvitationParams, { rejectValue: ThunkError }>(
    'patient/denyClinicInvitation',
    async (params, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/invitations/${params.invitationId}/deny`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
            },
            true
        );

        if (response.status !== StatusCodes.NO_CONTENT) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedDenyClinicInvitation'
                )} (${response.msg})`,
            });
        }

        return null;
    }
);
