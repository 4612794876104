import { combineReducers } from '@reduxjs/toolkit';

import appReducer from './app/app.slice';
import authenticationReducer from './authentication/authentication.slice';
import clinicsReducer from './clinics/clinics.slice';
import profileReducer from './profile/profile.slice';
import uiReducer from './ui/ui.slice';

const rootReducer = combineReducers({
    app: appReducer,
    authentication: authenticationReducer,
    clinics: clinicsReducer,
    profile: profileReducer,
    ui: uiReducer,
});

export default rootReducer;
