import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { IAppState } from 'src/store/app/app.types';
import AnI18NextLibHelper from 'src/helpers/AnI18NextLibHelper';
import DateTimeHelper from 'src/helpers/DateTimeHelper';
import SystemHelper from 'src/helpers/SystemHelper';
import UtilityHelper from 'src/helpers/UtilityHelper';

import { IPatient, IProfileState, PatientSmsNumber } from './profile.types';

import { IAuthenticationState } from '../authentication/authentication.types';
import { RootState } from '../store';
import { ThunkError } from '../root.types';
import { PatientProfileBody } from '../../model/patientProfileBody';

const fetchProfileData = async (
    appState: IAppState,
    authenticationState: IAuthenticationState,
    rejectWithValue: any
): Promise<IPatient> => {
    // Use local token data since they may not have been stored in Redux store yet
    const url = `${appState.endpointPwdUrlPrefix}/patient/profile?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);
    const payload: IPatient = response?.data?.data?.item ?? {};
    const status = response.status;

    payload.reportDaysAvailableString = '0';
    payload.isEnrolled = false;
    payload.isUnenrolled = false;
    payload.isOnboarded = false;
    payload.receivedData = true;
    payload.userType = 'patient';

    /*
        There are 4 possible cases which will trigger 3 different UX on front end:
            a) User in PodderCentral and enrolled in Discover:
                Profile in database?
                Yes: 200 OK
                No:
                    Trigger ETL (pull data into the database in real-time):
                        Success: Proceed with “d”
                        Failure: 424 Failed Dependency, show “Oh No” UX
            b) In PodderCentral and never enrolled in Discover:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            c) In PodderCentral and chose to unenroll:
                enrollmentStatus == true: 200 OK
                enrollmentStatus == false: 401 Unauthorized, show “You are unenrolled” UX
            d) In PodderCentral and enrollment pending:
                enrollmentPending == false*: 200 OK
                enrollmentPending == true*: 404 Not Found, show “Something is missing” UX.


        200 Normal ops
        401 Show "You are unenrolled" UX
        404 Show "Something is missing" UX
        else Show "Oh No" UX
    */
    if (status === StatusCodes.OK && !UtilityHelper.IsNull(payload.firstJoined)) {
        payload.isEnrolled = true;
    } else if (status === StatusCodes.UNAUTHORIZED) {
        payload.isUnenrolled = true;
    } else if (status !== StatusCodes.NOT_FOUND) {
        return rejectWithValue({ message: `${response.msg}.` });
    }

    if (UtilityHelper.IsNull(payload.clinicName)) {
        payload.clinicName = 'Clinic Name Here';
    }

    if (UtilityHelper.IsNull(payload.physicianName)) {
        payload.physicianName = 'Physician Name Here';
    }

    if (UtilityHelper.IsNull(payload.dateOfBirth)) {
        payload.dateOfBirth = DateTimeHelper.GetIsoNow();
    }

    return payload;
};

export const fetchProfile = createAsyncThunk<IProfileState, any, { rejectValue: Error }>(
    'patient/fetchProfile',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState();
        const appState: IAppState = state.app;
        const authenticationState: IAuthenticationState = state.authentication;

        const { access, aud, id, expires } = authenticationState?.oktaData || {};

        const isTokenValid =
            access &&
            aud &&
            id &&
            expires &&
            DateTimeHelper.GetDurationInSeconds(DateTimeHelper.GetIsoNow(), expires) > 0;

        if (!isTokenValid) {
            return rejectWithValue({
                message: AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'signIn.signInRequired'),
            });
        }

        return fetchProfileData(appState, authenticationState, rejectWithValue);
    }
);

export const updateProfile = createAsyncThunk<PatientProfileBody, PatientProfileBody, { rejectValue: ThunkError }>(
    'patient​/updateProfile',
    async (updatePayload: PatientProfileBody, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/profile`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedProfileUpdate')} (${
                    response.msg
                })`,
            });
        }

        return updatePayload;
    }
);

export const saveAndConfirmSmsNumber = createAsyncThunk(
    'patient/saveAndConfirmSmsNumber',
    async (updatePayload: PatientSmsNumber, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/profile/save-and-confirm-sms-number`;

        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'PUT',
                body: JSON.stringify(updatePayload),
            },
            true
        );

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedSmsNumberUpdate')}`,
            });
        }

        return updatePayload;
    }
);
