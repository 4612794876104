import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { RootState } from 'src/store/store';

import { Clinic } from '../../model/clinic';
import PromptModal from '../promptModal/PromptModal';
import MenuHelper from '../../helpers/MenuHelper';

function InvitedToClinicModal() {
    const { t } = useTranslation();

    const clinicsData = useSelector((state: RootState) => state.clinics.data);
    const signInUrl = useSelector((state: RootState) => state.app.signInUrl);

    const [showInvitedToClinicModal, setShowInvitedToClinicModal] = useState(false);

    useEffect(() => {
        const hasInvitedClinics = clinicsData.some(
            (clinic) => clinic.patientClinicAssociationStatus === Clinic.PatientClinicAssociationStatusEnum.Invited
        );

        const isComingFromLogin = !!signInUrl;

        setShowInvitedToClinicModal(hasInvitedClinics && isComingFromLogin);
    }, [clinicsData, signInUrl]);

    const handleCloseInvitedToClinicModalClick = () => {
        setShowInvitedToClinicModal(false);
    };

    return (
        <PromptModal
            confirmButtonLabel={t('invitedToClinicModal.buttonLabel')}
            description={t('invitedToClinicModal.description')}
            displayCloseButton={false}
            displayIcon={false}
            isOpen={showInvitedToClinicModal}
            onClose={handleCloseInvitedToClinicModalClick}
            onConfirmButtonClick={MenuHelper.redirectToSettings}
            title={t('invitedToClinicModal.title')}
        />
    );
}

export default InvitedToClinicModal;
