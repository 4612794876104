import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

import { ICachedResponse, IIDataItemReadings } from './app.types';

import {
    DataResponseInsight3,
    DataResponseInsight3GlucoseTrend,
    DataResponseInsulinUsageItem,
    DataResponseInsulinUsageTrendItem,
    LearningMaterialProgressResponseItem,
    LearningMaterialResourceResponseItem,
} from '../../model/models';
import {
    IDashboardRequest,
    IInsightFeedback,
    IDashboardFetchPayload,
    IDashboardSummaryFetchPayload,
    IDashboardSummaryGlucoseTrendFetchPayload,
    IReadingSub,
} from '../../types';
import { RootState } from '../store';
import { ThunkError } from '../root.types';
import AnI18NextLibHelper from '../../helpers/AnI18NextLibHelper';
import InsightHelper from '../../helpers/InsightHelper';
import SystemHelper from '../../helpers/SystemHelper';

export const sendFeedback = createAsyncThunk<IInsightFeedback, IInsightFeedback, { rejectValue: ThunkError }>(
    'patient​/sendFeedback',
    async (feedback: IInsightFeedback, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const url = `${appState.endpointPwdUrlPrefix}/patient/insights/feedback`;
        const response = await SystemHelper.Fetch(
            appState,
            authenticationState,
            url,
            {
                method: 'POST',
                body: JSON.stringify({ feedback }),
            },
            true
        );

        if (!(response.status === StatusCodes.OK || response.status === StatusCodes.NO_CONTENT)) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedFeedback')} (${
                    response.msg
                })`,
            });
        }

        return feedback;
    }
);

export const fetchBackEndId = createAsyncThunk<string, any, { rejectValue: ThunkError }>(
    'patient​/fetchBackEndId',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const queryParams = [''];
        const url = `${appState.endpointPwdUrlPrefix}/?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true, true);

        if (response.status !== StatusCodes.OK) {
            return rejectWithValue({
                message: `${response.msg}.`,
            });
        }

        return response.data;
    }
);

export const fetchLearningMaterialResources = createAsyncThunk<
    ICachedResponse<LearningMaterialResourceResponseItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchLearningMaterialResources', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const learningMaterialResourceItem: LearningMaterialResourceResponseItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/resources?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        learningMaterialResourceItem.item = response.data?.data?.item;
    } else {
        learningMaterialResourceItem.item = {
            resources: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedLearningMaterialResource'
            )} (${response.msg})`,
        });
    }

    return {
        data: learningMaterialResourceItem,
    };
});

export const fetchLearningMaterialOp5Progress = createAsyncThunk<
    ICachedResponse<LearningMaterialProgressResponseItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>(
    'patient​/fetchLearningMaterialOp5Progress',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const learningMaterialProgress: LearningMaterialProgressResponseItem = {
            item: null,
        };
        const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `i18n=${params.i18n ?? 'en_US'}`];
        const url = `${appState.endpointPwdUrlPrefix}/patient/learning-material/op5-progress?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
        const haveValidStatus =
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.BAD_REQUEST ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR;

        if (response.status === StatusCodes.OK) {
            learningMaterialProgress.item = response.data?.data?.item;
        } else {
            learningMaterialProgress.item = {
                progress: {},
            };
        }

        if (!haveValidStatus) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedLearningMaterialProgress'
                )} (${response.msg})`,
            });
        }

        return {
            data: learningMaterialProgress,
        };
    }
);

export const fetchDashboardInsulinUsage = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardInsulinUsage', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsage: DataResponseInsulinUsageItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsage.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsage.item = {
            dailyUsage: {},
            displayUsageExplanation: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardInsulinUsage')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardInsulinUsage,
    };
});

export const fetchDashboardInsulinUsageTrend = createAsyncThunk<
    ICachedResponse<DataResponseInsulinUsageTrendItem>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardInsulinUsageTrend', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardInsulinUsageTrend: DataResponseInsulinUsageTrendItem = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/insulin-usage/trend?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);
    const haveValidStatus =
        response.status === StatusCodes.OK ||
        response.status === StatusCodes.NOT_FOUND ||
        response.status === StatusCodes.BAD_REQUEST ||
        response.status === StatusCodes.INTERNAL_SERVER_ERROR;

    if (response.status === StatusCodes.OK) {
        dashboardInsulinUsageTrend.item = response.data?.data?.item;
    } else {
        dashboardInsulinUsageTrend.item = {
            weeklyTrend: {},
        };
    }

    if (!haveValidStatus) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(
                appState?.anI18Nextlib,
                'errors.failedDashboardInsulinUsageTrend'
            )} (${response.msg})`,
        });
    }

    return {
        data: dashboardInsulinUsageTrend,
    };
});

export const fetchDashboardSummary = createAsyncThunk<
    ICachedResponse<IDashboardSummaryFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboardSummary', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardSummaryFetchPayload: IDashboardSummaryFetchPayload = {
        item: null,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: DataResponseInsight3 = response.data;

        dashboardSummaryFetchPayload.item = wrapper?.data?.item;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardSummaryFetchPayload.item = [] as any;
    }

    if (dashboardSummaryFetchPayload.item === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboardSummary')} (${
                response.msg
            })`,
        });
    }

    return {
        data: dashboardSummaryFetchPayload,
    };
});

export const fetchDashboardSummaryGlucoseTrend = createAsyncThunk<
    ICachedResponse<IDashboardSummaryGlucoseTrendFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>(
    'patient​/fetchDashboardSummaryGlucoseTrend',
    async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;
        const authenticationState = state.authentication;

        const dashboardSummaryGlucoseTrendFetchPayload: IDashboardSummaryGlucoseTrendFetchPayload = {
            item: null,
        };

        const queryParams = [`beg=${params.beg}`, `end=${params.end}`];
        const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard/summary/glucose-trend?${queryParams.join('&')}`;
        const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

        if (response.status === StatusCodes.OK) {
            const wrapper: DataResponseInsight3GlucoseTrend = response.data;

            dashboardSummaryGlucoseTrendFetchPayload.item = wrapper?.data?.item;
        } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
            dashboardSummaryGlucoseTrendFetchPayload.item = [] as any;
        }

        if (dashboardSummaryGlucoseTrendFetchPayload.item === null) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(
                    appState?.anI18Nextlib,
                    'errors.failedDashboardSummaryGlucoseTrend'
                )} (${response.msg})`,
            });
        }

        return {
            id: params.endUtc,
            data: dashboardSummaryGlucoseTrendFetchPayload,
        };
    }
);

export const fetchDashboard = createAsyncThunk<
    ICachedResponse<IDashboardFetchPayload>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchDashboard', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    const dashboardFetchPayload: IDashboardFetchPayload = {
        items: null,
        totalCount: 0,
    };
    const queryParams = [`beg=${params.beg}`, `end=${params.end}`, `limit=${params.limit}`, `offset=${params.offset}`];
    const url = `${appState.endpointPwdUrlPrefix}/patient/dashboard?${queryParams.join('&')}`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, false, true);

    if (response.status === StatusCodes.OK) {
        const wrapper: IDashboardFetchPayload = response.data?.data;

        dashboardFetchPayload.items = wrapper.items;
        dashboardFetchPayload.totalCount = wrapper.totalCount;
    } else if (response.status === StatusCodes.UNAUTHORIZED || response.status === StatusCodes.NOT_FOUND) {
        dashboardFetchPayload.items = [];
    }

    if (dashboardFetchPayload.items === null) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedDashboard')} (${
                response.msg
            })`,
        });
    }

    return {
        id: params.end,
        data: dashboardFetchPayload,
    } as IDashboardFetchPayload;
});

export const fetchInsightEventReadings = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventReadings', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/readings?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (response.status !== StatusCodes.OK) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventReadings')} (${
                response.msg
            })`,
        });
    } else {
        const readings = response.data?.data?.item?.readings ?? [];

        dataReadings = {
            readings: readings,
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedReadings: true,
        },
    };
});

export const fetchInsightEventBoluses = createAsyncThunk<
    ICachedResponse<IIDataItemReadings>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventBoluses', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/boluses?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventBoluses')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            boluses: response.data?.data?.item?.boluses ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedBoluses: true,
        },
    };
});

export const fetchInsightEventModes = createAsyncThunk<
    ICachedResponse<IReadingSub>,
    IDashboardRequest,
    { rejectValue: ThunkError }
>('patient​/fetchInsightEventModes', async (params: IDashboardRequest, { getState, rejectWithValue }: any) => {
    const state = getState() as RootState;
    const appState = state.app;
    const authenticationState = state.authentication;

    let dataReadings;
    const insightId = InsightHelper.ConvertInsightIdSingleToChildAndParent(params?.insightId).child;
    const url = `${appState.endpointPwdUrlPrefix}/patient/insights/${insightId}/${params.eventId}/modes?`;
    const response = await SystemHelper.Fetch(appState, authenticationState, url, undefined, undefined, true);

    if (
        !(
            response.status === StatusCodes.OK ||
            response.status === StatusCodes.NOT_FOUND ||
            response.status === StatusCodes.INTERNAL_SERVER_ERROR
        )
    ) {
        return rejectWithValue({
            message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.failedEventModes')} (${
                response.msg
            })`,
        });
    } else {
        dataReadings = {
            modes: response.data?.data?.item?.modes ?? [],
            podStatuses: response.data?.data?.item?.podStatuses ?? [],
            submodes: response.data?.data?.item?.submodes ?? [],
        };
    }

    return {
        id: params.eventId,
        id2: insightId,
        data: {
            ...appState.currentReadingCombo,
            ...dataReadings,
            fetchedModes: true,
        },
    };
});

export const fetchHcpReport = createAsyncThunk<number, IDashboardRequest, { rejectValue: ThunkError }>(
    'patient​/fetchHcpReport',
    async (params: any, { getState, rejectWithValue }: any) => {
        const state = getState() as RootState;
        const appState = state.app;

        if ((params?.numDays ?? -1) < 0) {
            return rejectWithValue({
                message: `${AnI18NextLibHelper.Translate(appState?.anI18Nextlib, 'errors.invalidResponse')}`,
            });
        }

        return params?.numDays;
    }
);
