import { createSlice } from '@reduxjs/toolkit';

import sendFeedbackPending from './extraReducers/sendFeedback/pending.extraReducer';
import sendFeedbackFulfilled from './extraReducers/sendFeedback/fulfilled.extraReducer';
import sendFeedbackRejected from './extraReducers/sendFeedback/rejected.extraReducer';
import fetchBackEndIdPending from './extraReducers/fetchBackEndId/pending.extraReducer';
import fetchBackEndIdFulfilled from './extraReducers/fetchBackEndId/fulfilled.extraReducer';
import fetchBackEndIdRejected from './extraReducers/fetchBackEndId/rejected.extraReducer';
import updateProfilePending from './extraReducers/updateProfile/pending.extraReducer';
import updateProfileFulfilled from './extraReducers/updateProfile/fulfilled.extraReducer';
import updateProfileRejected from './extraReducers/updateProfile/rejected.extraReducer';
import fetchProfilePending from './extraReducers/fetchProfile/pending.extraReducer';
import fetchProfileFulfilled from './extraReducers/fetchProfile/fulfilled.extraReducer';
import fetchProfileRejected from './extraReducers/fetchProfile/rejected.extraReducer';
import fetchLearningMaterialResourcesPending from './extraReducers/fetchLearningMaterialResources/pending.extraReducer';
import fetchLearningMaterialResourcesFulfilled from './extraReducers/fetchLearningMaterialResources/fulfilled.extraReducer';
import fetchLearningMaterialResourcesRejected from './extraReducers/fetchLearningMaterialResources/rejected.extraReducer';
import fetchLearningMaterialOp5ProgressPending from './extraReducers/fetchLearningMaterialOp5Progress/pending.extraReducer';
import fetchLearningMaterialOp5ProgressFulfilled from './extraReducers/fetchLearningMaterialOp5Progress/fulfilled.extraReducer';
import fetchLearningMaterialOp5ProgressRejected from './extraReducers/fetchLearningMaterialOp5Progress/rejected.extraReducer';
import fetchDashboardInsulinUsagePending from './extraReducers/fetchDashboardInsulinUsage/pending.extraReducer';
import fetchDashboardInsulinUsageFulfilled from './extraReducers/fetchDashboardInsulinUsage/fulfilled.extraReducer';
import fetchDashboardInsulinUsageRejected from './extraReducers/fetchDashboardInsulinUsage/rejected.extraReducer';
import fetchDashboardInsulinUsageTrendPending from './extraReducers/fetchDashboardInsulinUsageTrend/pending.extraReducer';
import fetchDashboardInsulinUsageTrendFulfilled from './extraReducers/fetchDashboardInsulinUsageTrend/fulfilled.extraReducer';
import fetchDashboardInsulinUsageTrendRejected from './extraReducers/fetchDashboardInsulinUsageTrend/rejected.extraReducer';
import fetchDashboardSummaryPending from './extraReducers/fetchDashboardSummary/pending.extraReducer';
import fetchDashboardSummaryFulfilled from './extraReducers/fetchDashboardSummary/fulfilled.extraReducer';
import fetchDashboardSummaryRejected from './extraReducers/fetchDashboardSummary/rejected.extraReducer';
import fetchDashboardSummaryGlucoseTrendPending from './extraReducers/fetchDashboardSummaryGlucoseTrend/pending.extraReducer';
import fetchDashboardSummaryGlucoseTrendFulfilled from './extraReducers/fetchDashboardSummaryGlucoseTrend/fulfilled.extraReducer';
import fetchDashboardSummaryGlucoseTrendRejected from './extraReducers/fetchDashboardSummaryGlucoseTrend/rejected.extraReducer';
import fetchDashboardPending from './extraReducers/fetchDashboard/pending.extraReducer';
import fetchDashboardFulfilled from './extraReducers/fetchDashboard/fulfilled.extraReducer';
import fetchDashboardRejected from './extraReducers/fetchDashboard/rejected.extraReducer';
import fetchInsightEventReadingsPending from './extraReducers/fetchInsightEventReadings/pending.extraReducer';
import fetchInsightEventReadingsFulfilled from './extraReducers/fetchInsightEventReadings/fulfilled.extraReducer';
import fetchInsightEventReadingsRejected from './extraReducers/fetchInsightEventReadings/rejected.extraReducer';
import fetchInsightEventBolusesPending from './extraReducers/fetchInsightEventBoluses/pending.extraReducer';
import fetchInsightEventBolusesFulfilled from './extraReducers/fetchInsightEventBoluses/fulfilled.extraReducer';
import fetchInsightEventBolusesRejected from './extraReducers/fetchInsightEventBoluses/rejected.extraReducer';
import fetchInsightEventModesPending from './extraReducers/fetchInsightEventModes/pending.extraReducer';
import fetchInsightEventModesFulfilled from './extraReducers/fetchInsightEventModes/fulfilled.extraReducer';
import fetchInsightEventModesRejected from './extraReducers/fetchInsightEventModes/rejected.extraReducer';
import fetchHcpReportPending from './extraReducers/fetchHcpReport/pending.extraReducer';
import fetchHcpReportFulfilled from './extraReducers/fetchHcpReport/fulfilled.extraReducer';
import fetchHcpReportRejected from './extraReducers/fetchHcpReport/rejected.extraReducer';
import fetchClinicsPending from './extraReducers/fetchClinics/pending.extraReducer';
import fetchClinicsFulfilled from './extraReducers/fetchClinics/fulfilled.extraReducer';
import fetchClinicsRejected from './extraReducers/fetchClinics/rejected.extraReducer';
import acceptClinicInvitationPending from './extraReducers/acceptClinicInvitation/pending.extraReducer';
import acceptClinicInvitationFulfilled from './extraReducers/acceptClinicInvitation/fulfilled.extraReducer';
import acceptClinicInvitationRejected from './extraReducers/acceptClinicInvitation/rejected.extraReducer';
import denyClinicInvitationPending from './extraReducers/denyClinicInvitation/pending.extraReducer';
import denyClinicInvitationFulfilled from './extraReducers/denyClinicInvitation/fulfilled.extraReducer';
import denyClinicInvitationRejected from './extraReducers/denyClinicInvitation/rejected.extraReducer';
import {
    fetchBackEndId,
    fetchDashboard,
    fetchDashboardInsulinUsage,
    fetchDashboardInsulinUsageTrend,
    fetchDashboardSummary,
    fetchDashboardSummaryGlucoseTrend,
    fetchHcpReport,
    fetchInsightEventBoluses,
    fetchInsightEventModes,
    fetchInsightEventReadings,
    fetchLearningMaterialOp5Progress,
    fetchLearningMaterialResources,
    sendFeedback,
} from './app.thunks';
import { appInitialState } from './app.initialState';
import appReducers from './app.reducers';
import restoreStateFromLocalStorageFulfilled from './extraReducers/restoreStateFromLocalStorage/fulfilled.extraReducer';

import { resetStore, restoreStateFromLocalStorage } from '../root.actions';
import { acceptClinicInvitation, denyClinicInvitation, fetchClinics } from '../clinics/clinics.thunks';
import { fetchProfile, updateProfile } from '../profile/profile.thunks';

const appSlice = createSlice({
    name: 'app',
    initialState: appInitialState,
    reducers: appReducers,
    extraReducers: (builder) => {
        builder
            .addCase(restoreStateFromLocalStorage, restoreStateFromLocalStorageFulfilled)
            .addCase(resetStore, () => appInitialState)
            .addCase(sendFeedback.pending, sendFeedbackPending)
            .addCase(sendFeedback.fulfilled, sendFeedbackFulfilled)
            .addCase(sendFeedback.rejected, sendFeedbackRejected)
            .addCase(fetchBackEndId.pending, fetchBackEndIdPending)
            .addCase(fetchBackEndId.fulfilled, fetchBackEndIdFulfilled)
            .addCase(fetchBackEndId.rejected, fetchBackEndIdRejected)
            .addCase(updateProfile.pending, updateProfilePending)
            .addCase(updateProfile.fulfilled, updateProfileFulfilled)
            .addCase(updateProfile.rejected, updateProfileRejected)
            .addCase(fetchProfile.pending, fetchProfilePending)
            .addCase(fetchProfile.fulfilled, fetchProfileFulfilled)
            .addCase(fetchProfile.rejected, fetchProfileRejected)
            .addCase(fetchLearningMaterialResources.pending, fetchLearningMaterialResourcesPending)
            .addCase(fetchLearningMaterialResources.fulfilled, fetchLearningMaterialResourcesFulfilled)
            .addCase(fetchLearningMaterialResources.rejected, fetchLearningMaterialResourcesRejected)
            .addCase(fetchLearningMaterialOp5Progress.pending, fetchLearningMaterialOp5ProgressPending)
            .addCase(fetchLearningMaterialOp5Progress.fulfilled, fetchLearningMaterialOp5ProgressFulfilled)
            .addCase(fetchLearningMaterialOp5Progress.rejected, fetchLearningMaterialOp5ProgressRejected)
            .addCase(fetchDashboardInsulinUsage.pending, fetchDashboardInsulinUsagePending)
            .addCase(fetchDashboardInsulinUsage.fulfilled, fetchDashboardInsulinUsageFulfilled)
            .addCase(fetchDashboardInsulinUsage.rejected, fetchDashboardInsulinUsageRejected)
            .addCase(fetchDashboardInsulinUsageTrend.pending, fetchDashboardInsulinUsageTrendPending)
            .addCase(fetchDashboardInsulinUsageTrend.fulfilled, fetchDashboardInsulinUsageTrendFulfilled)
            .addCase(fetchDashboardInsulinUsageTrend.rejected, fetchDashboardInsulinUsageTrendRejected)
            .addCase(fetchDashboardSummary.pending, fetchDashboardSummaryPending)
            .addCase(fetchDashboardSummary.fulfilled, fetchDashboardSummaryFulfilled)
            .addCase(fetchDashboardSummary.rejected, fetchDashboardSummaryRejected)
            .addCase(fetchDashboardSummaryGlucoseTrend.pending, fetchDashboardSummaryGlucoseTrendPending)
            .addCase(fetchDashboardSummaryGlucoseTrend.fulfilled, fetchDashboardSummaryGlucoseTrendFulfilled)
            .addCase(fetchDashboardSummaryGlucoseTrend.rejected, fetchDashboardSummaryGlucoseTrendRejected)
            .addCase(fetchDashboard.pending, fetchDashboardPending)
            .addCase(fetchDashboard.fulfilled, fetchDashboardFulfilled)
            .addCase(fetchDashboard.rejected, fetchDashboardRejected)
            .addCase(fetchInsightEventReadings.pending, fetchInsightEventReadingsPending)
            .addCase(fetchInsightEventReadings.fulfilled, fetchInsightEventReadingsFulfilled)
            .addCase(fetchInsightEventReadings.rejected, fetchInsightEventReadingsRejected)
            .addCase(fetchInsightEventBoluses.pending, fetchInsightEventBolusesPending)
            .addCase(fetchInsightEventBoluses.fulfilled, fetchInsightEventBolusesFulfilled)
            .addCase(fetchInsightEventBoluses.rejected, fetchInsightEventBolusesRejected)
            .addCase(fetchInsightEventModes.pending, fetchInsightEventModesPending)
            .addCase(fetchInsightEventModes.fulfilled, fetchInsightEventModesFulfilled)
            .addCase(fetchInsightEventModes.rejected, fetchInsightEventModesRejected)
            .addCase(fetchHcpReport.pending, fetchHcpReportPending)
            .addCase(fetchHcpReport.fulfilled, fetchHcpReportFulfilled)
            .addCase(fetchHcpReport.rejected, fetchHcpReportRejected)
            .addCase(fetchClinics.pending, fetchClinicsPending)
            .addCase(fetchClinics.fulfilled, fetchClinicsFulfilled)
            .addCase(fetchClinics.rejected, fetchClinicsRejected)
            .addCase(acceptClinicInvitation.pending, acceptClinicInvitationPending)
            .addCase(acceptClinicInvitation.fulfilled, acceptClinicInvitationFulfilled)
            .addCase(acceptClinicInvitation.rejected, acceptClinicInvitationRejected)
            .addCase(denyClinicInvitation.pending, denyClinicInvitationPending)
            .addCase(denyClinicInvitation.fulfilled, denyClinicInvitationFulfilled)
            .addCase(denyClinicInvitation.rejected, denyClinicInvitationRejected);
    },
});

export const {
    actions: {
        allReportsRowCnt: allReportsRowCntActionCreator,
        anI18NextLib: anI18NextLibActionCreator,
        contributingEventMetaData: contributingEventMetaDataActionCreator,
        dashboardMetaData: dashboardMetaDataActionCreator,
        endpointUrlPrefix: endpointUrlPrefixActionCreator,
        error: errorActionCreator,
        insightId: insightIdActionCreator,
        loading: loadingActionCreator,
        pollProfile: pollProfileActionCreator,
        setActiveHttpCalls,
        setMessage,
        setReadingsInfo: setReadingsInfoActionCreator,
        setReloadInProgress,
        signInUrl: signInUrlCreator,
    },
} = appSlice;

export default appSlice.reducer;
