import React from 'react';
import { Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Onboarding from './Onboarding';

import { RootState } from '../../store/store';
import SystemHelper from '../../helpers/SystemHelper';
import EnrollmentRevoked from '../common/contentWrappers/EnrollmentRevoked';
import EnrollmentPending from '../common/contentWrappers/EnrollmentPending';

interface IEnrollmentRequiredRoute {
    component: React.FunctionComponent<any>;
    exact: boolean;
    path: string;
}

function EnrollmentRequiredRoute({ component: Component, exact, path }: Readonly<IEnrollmentRequiredRoute>) {
    const profileData = useSelector((state: RootState) => state.profile.data);

    if (!profileData.receivedData) return null;

    const showPending = !profileData.isUnenrolled && !profileData.isEnrolled;

    const podderCentralCb = () =>
        window.location.assign(SystemHelper?.GetRuntimeConfig('REACT_APP_PODDER_CENTRAL_URL_LINK'));

    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps) => {
                if (profileData.isUnenrolled) {
                    return <EnrollmentRevoked podderCentralCb={podderCentralCb} />;
                }

                if (showPending) {
                    return <EnrollmentPending podderCentralCb={podderCentralCb} />;
                }

                return <Onboarding>{Component && <Component {...routeProps} />}</Onboarding>;
            }}
        />
    );
}

export default EnrollmentRequiredRoute;
